.number-table-wrapper {
  /* width: 100%; */
  /* height: 200px; */
  padding: 20px;
  border: 1px solid #ccc;
  border-collapse: collapse;
}

.number-table-wrapper .header {
  display: flex;
  border: 1px solid #ccc;
  gap: 10px;
}

.number-table-wrapper .table-body {
  max-height: 400px;
  overflow: auto;
}

.number-table-wrapper .table-body input {
  height: 32px;
}

.number-table-wrapper .table-body .table-row {
  display: flex;
  width: 100%;
  border: 1px solid #ccc;
  gap: 10px;
}

.number-table-wrapper tr td Input {
  padding: 7px;
  width: initial !important;
}

.number-table-wrapper,
.number-table-wrapper thead th,
.number-table-wrapper tr,
.number-table-wrapper tr td {
  border: 1px solid #ccc;
}

.number-table-wrapper tr td,
.number-table-wrapper thead th {
  padding: 10px;
}

.phone-number .MuiInput-root::before {
  border: none;
}

.number-table-wrapper .serial-number {
  width: 4%;
  text-align: center;
  border-right: 1px solid #ccc;
  padding: 9px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.number-table-wrapper .header .phone-number {
  display: flex;
  align-items: center;
  justify-content: center;
}

.number-table-wrapper .phone-number {
  /* border: 1px solid #ccc; */
  padding: 9px 5px;
}

.MuiDialogContent-root {
  position: relative;
}

.dropzone .MuiGrid-root.MuiGrid-item {
  position: absolute;
  bottom: 30%;
  left: 40%;
}
.modal-content {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1.4;
  background: #f1f1f1;
  padding: 14px 28px;
  border-radius: 3px;
  max-width: 600px;
  min-width: 300px;
  z-index: 30;
}
.modalFooter {
  display: flex;
  justify-content: flex-end;
}
