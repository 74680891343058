.language-heading {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.language-heading-text {
    text-transform: uppercase;
    font-size: 1.5em;
}

.translations-table {
    width: 100%;
    margin-top: 10px;
}

.written-translation-cell {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.audio-translation-cell {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 5px;
}

.icon-holder {
    height: 30px;
    aspect-ratio: 1 / 1;
    border-radius: 7px;
    color: var(--app-text-color-white) !important;
    background-color: var(--blue);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon-holder:hover {
    background-color: var(--hover-grey) !important;
}