.mapSitesBox {
    color: var(--app-text-color-white) !important;
    background-color: var(--app-background-color-default) !important;
    border-radius: 7px;
    position: relative;
    width: 100%;
    margin: 10px 0px;
}

.mapSitesBoxBanner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
}

.mapSitesBoxBanner p {
    margin: 0 !important;
}

.mapSitesBoxBannerBorder {
    border-bottom: 1px solid var(--app-border-color-default) !important;
}

.mapSitesBoxContent {
    padding: 10px;
    position: relative;
    background-color: var(--app-background-color-white) !important;
    border-radius: 0px 0px 7px 7px;
    color: var(--app-text-color-default) !important;
    border: 1px solid var(--app-border-color-default) !important;
}

.mapSitesBoxContent h3 {
    padding: 0;
    margin: 0;
}


.mapSitesBoxIcons {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.displayedIcon {
    display: inline;
}

.hiddenIcon {
    display: none;
}

.visible {
    display: block;
}

.invisible {
    display: none;
}

.mapSitesBoxCheckBox {
    line-height: 1 !important;
    width: 100% !important;
}

.siteSelectMap {
    width: 100%;
    background-color: var(--app-background-color-white) !important;
    border-radius: 7px;
    z-index: 10;
    padding: 10px 10px;
    margin: 5px 0px;
  }
  