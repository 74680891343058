.dropdown-link {
    color: var(--app-text-color-white) !important;
    width: 100%;
    text-decoration: none !important;
}

.dropdown-menu {
    background-color: var(--app-background-color-default) !important;
    color: var(--app-text-color-white) !important;
    position: absolute;
    padding: 10px;
    list-style: none;
    text-align: start;
    overflow: hidden;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    border-radius: 0px 0px 5px 5px;
    font-size: 0.8em;
}

@media screen and (min-width: 1440px) {
    .dropdown-menu {
        font-size: 1em;
    }
}

.dropdown-menu li {
    background-color: var(--app-background-color-default) !important;
    color: var(--app-text-color-white) !important;
    text-decoration: none;
    margin: 8px 0;
    width: 100%;
}