.question-table .table th {
  width: 50% !important;
}
.table td {
  position: relative;
}

.warning-icon.MuiButton-text {
  position: absolute;
  right: 0;
  top: 20px;
}
