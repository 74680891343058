.siteSummary {
  position: relative;
  color: var(--app-text-color-white) !important;
  z-index: 10;
  width: 100%;
  display: flex;
}

.siteSummaryBox {
  color: var(--app-text-color-white) !important;
  background-color: var(--app-background-color-default) !important;
  border-radius: 7px;
  width: 100%;
  margin-left: 10px;
}

.siteSummaryBoxBanner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  height: 6vh;
}

.siteSummaryBoxBannerBorder {
  border-bottom: 1px solid var(--app-border-color-default) !important;
}

.siteSummaryBoxContent {
  padding: 10px;
  transition-property: height;
  transition-duration: 2s;
  background-color: #fff;
  border-radius: 0 0 7px 7px;
}

.siteSummaryBoxIcons {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.siteScoreCircle {
  margin: 0 !important;
  background: var(--green);
  height: 6vh;
  aspect-ratio: 1 / 1;
  color: #fff;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  font-weight: bold;
  font-size: x-large;
  position: absolute;
  left: -10%;
}

.scoreZero {
  background-color: var(--reports-grey) !important;
}

.scoreLow {
  background-color: var(--reports-negative) !important;
}

.scoreMedium {
  background-color: var(--reports-neutral) !important;
}

.scoreHigh {
  background-color: var(--reports-positive) !important;
}

.siteSummaryPrioritiesHoverBox {
  display: block;
  color: var(--app-text-color-default) !important;
  position: absolute;
  /* box-shadow: 1px 0px 6px 3px var(--app-background-color-default); */
  background-color: #fff;
  border-radius: 5px;
  z-index: 10;
  top: 30%;
  left: -26vw;
  width: 25vw;
}

.siteSummaryProgressHoverBox {
  display: block;
  color: var(--app-text-color-default) !important;
  position: absolute;
  /* box-shadow: 1px 0px 6px 3px #00000066; */
  background-color: #fff;
  border-radius: 5px;
  z-index: 10;
  top: 30%;
  left: -10vw;
  width: 10vw;
}

.hoverHolder {
  display: flex;
  flex-direction: column;
  width: 55%;
  border-radius: 7px;
}

.hoverItem {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.hoverItem a {
  cursor: pointer;
  margin-left: 10px;
  color: var(--app-text-color-default) !important;
  ;
}

.contentExpansion {
  cursor: pointer;
}

.siteSummaryBoxBanner p {
  margin: 0 !important;
}

.displayedIcon {
  display: inline;
}

.hiddenIcon {
  display: none;
}

.visible {
  display: block;
}

.invisible {
  display: none;
}

.spinnerContainer {
  height: 50px;
  width: 50px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.siteSummaryBoxContentCircle {
  margin: 0 !important;
  background: var(--app-background-color-default) !important;
  height: 4vh;
  aspect-ratio: 1 / 1;
  color: #fff;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold !important;
  font-size: large !important;
}