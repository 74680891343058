.prioritiesContainer {
  height: auto;
  margin: 5px;
  display: flex;
  flex-direction: column;
}

.prioritiesContainer tr, td {
  border: none;
}

.prioritiesOverviewLine {
  margin: 5px 0;
  width: 100%;
  color: var(--app-text-color-default) !important;
  font-size: 1em;
  font-weight: bold;
}

@media only screen and (max-width: 1550px) and (min-width: 1200px)   {
  .prioritiesOverviewLine {
    font-size: 0.85em;
  }
}

.percentageCell{
  display: flex;
  align-items: center;
  align-content: center;
  width: 100%;
  background-color: grey;
}

.percentageCircle {
  background-color: var(--reports-negative);
  color: var(--app-text-color-white) !important;
}
