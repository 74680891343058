div#spinner {
  display: none;
  border: 3px solid #f3f3f3;
  border-radius: 50%;
  border-top: 3px solid #3498db;
  width: 20px;
  height: 20px;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
  margin-top: 5px;
}

#spinner img {
  display: block;
  float: left;
}

.callcyclefilter-error {
  color: red;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/*Loading spinner start*/
.spinner-overlay {
  position: fixed;
  top: 0;
  left : 0;
  z-index: 500;
  width: 100%;
  height: 100%;
  display: block;
  background-image: url(../../assets/Images/AndWider_LogoMark.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 70px 70px;
  background-color: rgba(0, 0, 0, 0.6);
}

.cv-spinner {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  width: 150px;
  height: 150px;
  border: 4px #ddd solid;
  border-top: 4px #2e93e6 solid;
  border-radius: 50%;
  -webkit-animation: spin 1s linear infinite;
  animation: sp-anime 1s infinite linear;
}

@keyframes sp-anime {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
}

/*Loading spinner stop*/

/*Loading spinner start*/
.small-spinner-overlay {
  z-index: 500;
  width: 100%;
  height: 100%;
  display: block;
  background-image: url(../../assets/Images/AndWider_LogoMark.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 60% 60%;
}

.small-cv-spinner {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.small-spinner {
  width: 100%;
  height: 100%;
  border: 4px #ddd solid;
  border-top: 4px #2e93e6 solid;
  border-radius: 100%;
  -webkit-animation: spin 1s linear infinite;
  animation: sp-anime 1s infinite linear;
}

@keyframes sp-anime {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
}
