.language-container {
    display: flex;
    justify-content: space-between;
  }
  .table td {
    position: relative;
  }
  
  .warning-icon.MuiButton-text {
    position: absolute;
    right: 0;
    top: 28%;
  }
  