.infoBox {
  color: var(--app-text-color-white) !important;
  background-color: var(--app-background-color-default) !important;
  border-radius: 7px;
  /* z-index: 10; */
  position: relative;
  /* bottom: 0; */
  width: 100%;
  margin: 10px 0px;
}

.infoBoxBanner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.infoBoxBannerBorder {
  border-bottom: 1px solid var(--app-text-color-default) !important;;
}

.infoBoxContent {
  padding: 25px 10px;
  transition-property: height;
  transition-duration: 2s;
  background-color: var(--app-background-color-white) !important;
  border-radius: 0px 0px 7px 7px;
  color: var(--app-text-color-default) !important;;
  border: 1px solid var(--app-text-color-default) !important;;
}

.contentExpansion {
  cursor: pointer;
}

.infoBoxBanner p {
  margin: 0 !important;
}

.displayedIcon {
  display: inline;
}

.hiddenIcon {
  display: none;
}

.visible {
  display: block;
}

.invisible {
  display: none;
}