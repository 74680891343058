.button-wrapper {
    display: flex;
    float: right;
    column-gap: 10px;
  }
  
  .dropzone-area {
    width: 100%;
    margin: 45px auto;
  }
  
  .dropzone-area .MuiGrid-container {
    justify-content: center;
    margin-top: 20px !important;
  }
  .error-message {
    color: red;
    position: relative;
    top: 5px;
  }
  