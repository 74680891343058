.responseRatesKeyContainer {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}

.responseRatesKeyLine {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.responseRatesKeyColour {
  height: 20px;
  aspect-ratio: 1 / 1;
  border-radius: 100px;
  display: inline-block;
}

.responseRatesKeyText {
  padding-left: 10px;
  display: inline-block;
}



