/* Tooltip container */
.comparePieChart {
  position: relative;
  display: inline-block;
  width: 300px;
  height: 300px;
  border-radius: 50%;
}

/* Tooltip text */
.comparePieChart .hover-key {
  /* visibility: hidden; */
  background-color: var(--app-background-color-white) !important;
  color: var(--app-text-color-default) !important;
  text-align: center;
  padding: 10px;
  border-radius: 6px;

  /* Position the tooltip text */
  position: absolute;
  z-index: 50;
  /* bottom: 125%; */
  left: 50%;
  margin-left: -60px;

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

.compareAndBenchmark {
  display: inline-flex;
  justify-content: center;
}

.compareHeadingAndChart {
  margin-right: 1vw;
  margin-left: 1vw;
  text-align: center;
}

/* Tooltip arrow */
.comparePieChart .hover-key::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: white transparent transparent transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.comparePieChart:hover .hover-key {
  visibility: visible;
  opacity: 1;
}

.comparePieChartContainer {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.comparePieChartEmptyText {
  padding-left: 10px;
  display: inline-block;
  font-size: 1.2em
}


.comparePieChartEmpty {
  position: relative;
  display: inline-block;
  margin: 50px;
}