.report-menu-bar {
  display: flex;
  width: 100%;
  height: 6vh;
  flex-grow: 1;
  background-color: var(--app-background-color-default) !important;
  color: #fff !important;
  border-radius: 0 !important;
  justify-content: space-between !important;
  padding: 0 5%;
  margin: 0 !important;
  position: fixed;
  top: 9vh;
  z-index: 10;
  font-size: 1.2em;
  align-items: center;
}

.reports-menu-title {
  margin: 0 !important;
}

.reports-nav-item {
  box-sizing: border-box;
  display: inline-block;
  /* margin: auto 0px; */
  /* padding: 0 20px; */
  color: var(--app-text-color-white) !important;
  cursor: pointer;
}

.reports-menu-title-underlined {
  border-bottom: 2px solid white
}

.reports-section {
  height: 72vh;
  width: 98vw;
  padding: 0 2vw;
  top: 2vh;
  position: absolute;
}


/* .reports-nav-item-left {
  cursor: pointer;
  color: var(--app-text-color-white) !important;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
  align-items: center;
} */