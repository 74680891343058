.MuiGrid-grid-md-6 {
  padding: 0 15px 0 0;
}

.MuiGrid-grid-sm-4 {
  padding: 0 15px 0 0;
}

.makeStyles-formControl-33 {
  margin: 8px 0;
}

.MTableToolbar-title-22 {
  overflow: visible !important;
}

.custom-marginTop30 {
  margin-top: 30px !important;
}

.MTableToolbar-searchField-23 {
  width: auto !important;
}

.invie-org-form {
  background-color: var(--app-background-color-white) !important;
}

/* .MTableToolbar-searchField-17{
  width: auto !important;
} */

.site-table .table .MTableToolbar-spacer-12 {
  display: none !important;
}

.site-table .table .MTableToolbar-searchField-15 {
  padding-left: 0;
}

.custom-accordion {
  width: 100% !important;
  margin: 10px;
}