.barChartValues {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
}

/* Tooltip container */
.progressBarChartToolTip {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.progressBarChartToolTip .progressBarChartToolTipText {
  visibility: hidden;
  width: 100%;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: -5px;
  left: 105%;

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

/* Tooltip arrow */
.progressBarChartToolTip .progressBarChartToolTipText::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent #555 transparent transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.progressBarChartToolTip:hover .progressBarChartToolTipText {
  visibility: visible;
  opacity: 1;
}