/* Tooltip container */
.pieChart {
  position: relative !important;
  display: block !important;
  width: 350px;
  height: 350px;
  border-radius: 50%;
  margin: 0 auto;
}

.demographicPieChart {
  opacity: 0.6;
}

.demographicPieChartContainer {
  margin-top: 5vh;
  display: flex;
  justify-content: center;
}

/* Tooltip text */
.pieChart .hover-key {
  /* visibility: hidden; */
  background-color: var(--app-background-color-white) !important;
  color: black;
  text-align: center;
  padding: 10px;
  border-radius: 6px;

  /* Position the tooltip text */
  position: absolute;
  z-index: 50;
  /* bottom: 125%; */
  left: 50%;
  margin-left: -60px;

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

/* Tooltip arrow */
.pieChart .hover-key::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: white transparent transparent transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.pieChart:hover .hover-key {
  visibility: visible;
  opacity: 1;
}

.pieChartHeading {
  font-size: 30px;
  color: var(--app-text-color-default) !important;
  text-align: center;
  margin-bottom: 25px;
  font-weight: bold;
}
