.create-container {
  padding: 30px;
  margin-top: 20px;
}

.grid-custom-spacing {
  padding-left: 10px;
  padding-right: 10px;
}

.custom-marginTop30 {
  margin-top: 30px !important;
}

.custom-floatRight {
  float: right !important;
}

.MuiPopover-paper {
  max-width: 700px;
  max-height: 500px;
  overflow-x: auto;
}
