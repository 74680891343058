.filterBox {
  color: var(--app-text-color-white) !important;
  background-color: var(--app-background-color-default) !important;
  border-radius: 7px;
  position: relative;
  width: 100%;
  margin: 10px 0px;
}

.filterBoxBanner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.filterBoxBanner p {
  margin: 0 !important;
}

.filterBoxBannerBorder {
  border-bottom: 1px solid var(--app-border-color-default) !important;
}

.filterBoxContent {
  padding: 10px;
  position: relative;
  background-color: var(--app-background-color-white) !important;
  border-radius: 0px 0px 7px 7px;
  color: var(--app-text-color-default) !important;
  border: 1px solid var(--app-border-color-default) !important;
}

.filterBoxContent h3 {
  padding: 0;
  margin: 0;
}

.hidden {
  display: none;
}

.siteSummaryBoxIcons {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.displayedIcon {
  display: inline;
}

.hiddenIcon {
  display: none;
}

.visible {
  display: block;
}

.invisible {
  display: none;
}

.filterBoxCheckBox {
  line-height: 1 !important;
  width: 100% !important;
}