.nav-item{
    padding: 40px;
    box-sizing: border-box;
    display: inline-block;
    margin: auto 0px;
    padding: 0 10px;
    color: var(--app-text-color-white) !important;
}

.menu-item {
    font-size: 0.7em;
    color: var(--app-text-color-white) !important;
    text-decoration: none;
}

.menu-title {
    font-size: 0.7em;
    color: var(--app-text-color-white) !important;
    text-decoration: none;
}

@media screen and (min-width: 1440px) {
    .menu-title {
        font-size: 1em;
    }

    .menu-item {
        font-size: 1em;
    }
}

.dropdown-menu-item {
    padding: 0px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}