.button-wrapper {
  display: flex;
  float: right;
  column-gap: 10px;
}

.language-container p {
  display: flex;
  column-gap: 10px;
}
.language-container h4 {
  margin: 0;
}

.audio-file {
  display: flex;
  align-items: center;
  gap: 20px;
}

.dropzone-area {
  width: 500px;
  margin: 45px auto;
}

.dropzone-area .MuiGrid-container {
  justify-content: center;
  margin-top: 20px !important;
}
.error-message {
  color: red;
  position: relative;
  top: 5px;
}
