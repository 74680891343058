.benchmarkBox {
  color: var(--app-background-color-default) !important;
  background-color: var(--app-text-color-white) !important;
  border-radius: 7px;
  border: 2px solid var(--app-background-color-default);
  z-index: 10;
  width: 100%;
  margin: 10px 0px;
  display: inline-table;
}

.benchmarkBoxBanner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px;
}

.avgSiteScoreCircle {
  margin: 0 !important;
  background: var(--green);
  height: 95%;
  aspect-ratio: 1 / 1;
  color: #fff;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  font-weight: bold;
  font-size: x-large;
  min-width: 2vw;
}

.scoreZero {
  background-color: var(--reports-grey) !important;
}

.scoreLow {
  background-color: var(--reports-negative) !important;
}

.scoreMedium {
  background-color: var(--reports-neutral) !important;
}

.scoreHigh {
  background-color: var(--reports-positive) !important;
}

.spinnerContainer {
  margin-top: 2vh;
  margin-bottom: 2vh;
}