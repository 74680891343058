.MuiGrid-grid-md-6 {
  padding: 0 15px 0 0;
}
.MuiGrid-grid-sm-4 {
  padding: 0 15px 0 0;
}
.makeStyles-formControl-33 {
  margin: 8px 0;
}
.MuiFormControl-marginDense {
  width: 100%;
}
