.siteScoreContainer {
  aspect-ratio: 1 / 1;
  height: 100%;
  margin: 0 0vw;
  position: relative;
  align-items: center;
  display: flex;
  justify-content: center;
}

.rating {
  /* background-color: #f6d12b; */
  color: #fff;
  height: 80%;
  width: 80%;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  margin-left: 10px !important;
}

.scoreNoCompleted {
  background-color:var(--reports-grey) !important;
}

.scoreNoParticipation {
  background-color:var(--cancel-grey) !important;
}

.scoreLow {
  background-color: var(--reports-negative) !important;
}

.scoreMedium {
  background-color: var(--reports-neutral) !important;
}

.scoreHigh {
  background-color: var(--reports-positive) !important;
}

.siteRating {
  height: 10px;
  width: 62px;
  padding-bottom: 3%;
  background-color: yellow;
  margin: 30px;
}

.siteScoreContainer .small-spinner-overlay {
  width: 90%;
  height: 90%;
  background-size: 40% 40%;
}