table {
    border-collapse: collapse;
    width: 100%;
}

th,
td {
    border: 1px solid #e0e0e0;
    /* Light gray border */
    padding: 8px;
}