.MTableHeader-header-13 {
  z-index: none !important;
}

.custom-floatRight {
  float: right !important;
}

.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
  min-width: none;
}

.link-button:hover,
.link-button:focus {
  text-decoration: none;
}

.manageActions {
  width: 470px;
}
.manageActions span {
  margin: 0 5px;
}

.surveyCycleList {
  max-height: 30vh;
  overflow-y: scroll;
}

#dataCycleNote {
  height: auto !important;
}
/* .App {
  font-family: sans-serif;
}
.MuiTableSortLabel-icon {
  opacity: 1;
} */
