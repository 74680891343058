.statusBadgeTray {
  display: flex;
  flex-direction: row;
  height: 55vh;
  width: 100%;
  justify-items: space-between;
}

.statusBadgeImageContainer {
  width: 30%;
  max-height: 100%;
  height: auto;
}

.statusBadgeImage {
  width: 100%;
  height: auto;
}

.notQualified {
  opacity: 0.4;
  filter: grayscale(100);
}

.statusBadgeMessage h1 {
  margin: 0;
}