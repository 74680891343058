.mobilemenu-item {
  margin: 15px 0px;
}

.mobilemenu-icon {
  font-size: 0.75em;
}

.mobilemenu-subitem {
  text-decoration: none !important;
  list-style-type: none;
  color: var(--app-text-color-white) !important;
}
