.barChartContainer {
  width: 100%;
  padding: 0vh 4vw;
}

.barChart {
  height: 55vh;
  display: flex;
  justify-content: flex-start;
}

.barChartValues {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
}

.barChartValue {
  width: 50%;
  display: flex;
  flex-direction: column-reverse;
}

.barChartValue p {
  color: var(--app-text-color-white) !important;
  text-align: right !important;
  margin: 3px !important;
}

.barChartCategories {
  display: flex;
  justify-content: flex-start;
}

.segment {
  height: 100%;
  width: 16%;
  display: inline-block;
  margin-left: 3px;
}

.skinnySegment {
  height: 100%;
  width: 10%;
  display: inline-block;
}

.legend {
  height: 125%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.legendTextBox {
  height: 25%;
  text-align: right;
  padding: 3px;
  margin-right: 10px;
}

.legendTextBox p {
  margin: 0 !important;
}

/* Tooltip container */
.barChartToolTip {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.barChartToolTip .barChartToolTipText {
  visibility: hidden;
  width: 100px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: -5px;
  left: 105%;

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

/* Tooltip arrow */
.barChartToolTip .barChartToolTipText::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent #555 transparent transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.barChartToolTip:hover .barChartToolTipText {
  visibility: visible;
  opacity: 1;
}