.nav-wrapper {
  /* background-image: url(../../assets/Images/ThickBanner.svg); */
  height: 9vh;
  position: fixed;
  width: 100%;
  z-index: 100;
}

.nav-thick-banner {
  position: fixed;
  height: 1vh;
  width: 100%;
  overflow: hidden;
}

.nav-thick-banner img {
  width: auto;
}

.navbar {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 8vh;
  color: var(--app-text-color-white) !important;
  background-color: var(--app-background-color-default) !important;
  text-decoration: none;
  padding: 0 5%;
}

.navbar-logo {
  display: inline-block;
  text-decoration: none;
  color: var(--app-text-color-white) !important;
  margin: auto 0;
  height: 60%;
  cursor: pointer;
}

.navbar-logo img {
  display: inline-block;
  width: auto;
  height: 100%;
}

.navbar-icon {
  display: none;
  text-decoration: none;
  color: var(--app-text-color-white) !important;
  margin: auto 0;
  height: 40%;
  cursor: pointer;
}

.icon {
  display: inline-block;
  width: auto;
  height: 100%;
}

.nav-menu {
  display: inline-flex;
  justify-content: space-around;
  align-items: flex-start;
  list-style-type: none;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0px;
  height: 100%;
  line-height: 100%;
  margin: 0;
  right: 0;
  text-decoration: none;
  color: var(--app-text-color-white) !important;
}

.desktop {
  display: inline-flex;
  justify-content: space-around;
  align-items: flex-start;
  list-style-type: none;
}

.mobile {
  list-style-type: none;
  padding: 0;
}

.navmobile-hidden {
  display: none;
}

.navmobile-active {
  display: flex;
  position: absolute;
  color: var(--app-text-color-white) !important;
  list-style-type: none;
  background-color: #000000;
  width: 100%;
  padding: 0 8%;
  z-index: 100;
  top: 15vh;
}

@media screen and (min-width: 768px) {
  .navmobile-active {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .nav-wrapper {
    min-height: 85px;
  }

  .navbar {
    min-height: 80px;
  }

  .nav-menu {
    display: none;
  }

  .navbar-icon {
    display: inline-block;
  }
}

@media screen and (max-width: 425px) {
  .nav-wrapper {
    min-height: 75px;
  }

  .navbar {
    min-height: 70px;
  }
}

@media screen and (max-width: 320px) {
  .nav-wrapper {
    min-height: 55px;
  }

  .navbar {
    min-height: 50px;
  }
}