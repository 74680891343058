.create-container {
    padding: 30px;
    margin-top: 20px;
  }
  
  .grid-custom-spacing {
    padding-left: 10px;
    padding-right: 10px;
  }
  
  .custom-marginTop30 {
    margin-top: 30px !important;
  }
  
  .custom-floatRight {
    float: right !important;
  }

td {
  padding: 0.25em;
  border: 1px solid;
}

th {
  padding: 0.25em;
  text-align: left;
  border: 1px solid;
}

table {
  width: 40vw;
  border: 1px solid;
  border-collapse: collapse;
}

