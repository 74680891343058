.suggestions {
    outline: 0;
    position: absolute;
    max-width: calc(100% - 32px);
    max-height: calc(100% - 32px);
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 10;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background-color: #fff;
}