.accordianHolder {
  display: flex !important;
  flex-direction: column;
  height: 100% !important;
  justify-content: space-between;
  width: 20vw;
}

.left {
  position: fixed;
  height: 75vh !important;
  overflow-y: scroll !important;
  justify-content: flex-start;
}

.small {
  width: 10vw !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.left::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.left {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.right {
  height: 62vh !important;
  position: fixed;
  right: 0vw;
  display: block;
  justify-content: flex-start;
  align-items: center;
  top: 35vh;
  overflow-y: scroll;
  scrollbar-width: none;
}

.chartHolder {
  width: 63vw !important;
  height: 78vh;
  position: fixed;
  left: 22vw;
  padding-left: 2vw;
  overflow-y: scroll;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.chartHolder::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.chartHolder {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.charts-content {
  position: relative;
  width: 100%;
  top: 18vh;
}

.accordianWrapper {
  display: flex;
  height: 100%;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  width: 100%;
  margin-right: 1vw;
}


.topControl {
  display: flex;
  height: 9vh;
  width: 72%;
  position: fixed;
  background-color: var(--app-background-color-white);
  padding-top: 0vh;
  padding-bottom: 4vh;
  z-index: 50;
  justify-content: space-between;
}

@media screen and (max-width: 1440px) {
  .topControl {
    height: 12vh;
  }
}

.chartSection {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  height: 85%;
  width: 100%;
  /* padding: 2vh 0vw; */
  position: absolute;
  top: 9vh;
  /* margin-top: 2vh; */
}

.tabDetails {
  height: 100%;
  width: 100%;
  position: relative;
}

/* .charts-Header {
  padding-bottom: 100px;
} */

.workplace-selector,
.template-type-selector {
  width: 100%;
  margin: 10px 0px;
  display: flex;
  justify-content: flex-start;
  border-radius: 7px;
  background-color: var(--app-text-color-default);
  color: var(--app-text-color-white);
  max-height: 44px !important;
}

.selector {
  width: 100%;
  margin: 0 5px;
}

/* @media screen and (min-width: 1440px) {
  .chartHolder {
    width: 120vw !important;
  }
} */

.reportFilter {
  min-width: 15vw;
  display: flex !important;
  min-height: 50px !important;
  align-items: center;
  justify-content: space-around;
  border-radius: 7px !important;
}

.tabDetails .controlHeader svg {
  color: #fff !important;
  font-size: 22px;
}

.controlHeader {
  color: #fff !important;
  font-size: 22px;
}

.tabDetails .controlHeader p {
  padding: 0px 15px;
  line-height: 28px;
  display: flex;
  gap: 10px;
}

.tabDetails .accordian2 .controlContent {
  background-color: #77787b;
  color: #fff;
  border-top: 1px solid #ccc;
}

.tabDetails .accordian {
  left: 60px;
  top: 10%;
  z-index: 9;
}

.tabDetails .accordian1 {
  left: 5%;
  top: 45%;
}

.tabDetails .accordian1 .controlHeader,
.tabDetails .accordian2 .controlHeader {
  background-color: #77787b !important;
}

.tabDetails .accordian1 svg,
.tabDetails .accordian2 svg {
  font-size: 25px;
}

.controlHeader.Mui-expanded .hide {
  display: block;
}

.controlHeader.Mui-expanded .show {
  display: none;
}

.hide {
  display: none;
}

.tabDetails .accordian1 svg.hide,
.tabDetails .accordian2 svg.hide {
  font-size: 20px;
}

.css-yw020d-MuiAccordionSummary-expandIconWrapper {
  transform: none !important;
}

/* .tabDetails .accordianTop.center {
  left: initial;
  transform: none;
}

.tabDetails .accordianTop.left {
  left: initial;
}

.tabDetails .accordianTop.right {
  right: initial !important;
} */

.tabDetails .Select-dropdown {
  width: 100%;
}

.tabDetails .siteRating {
  height: 10px;
  width: 62px;
  padding-bottom: 3%;
  background-color: yellow;
  margin: 30px;
}

.tabDetails .MuiCollapse-wrapperInner {
  max-height: 400px;
  overflow: auto;
}

.apx-legend-position-right {
  right: 0 !important;
}

.MuiAccordion-root.Mui-expanded {
  margin: 0 !important;
}

.tabDetails .MuiAccordion-root.accordian:before {
  background: none !important;
}

.customControls1 {
  width: 100%;
  z-index: 9;
  margin: 0 !important;
  border-radius: 7px !important;
}

.tabDetails .customControls1 .controlHeader {
  background-color: #77787b !important;
}

.participationReportSelect {
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  border-radius: 7px !important;
  background-color: #e4e4e5 !important;
  color: #b7b7b7 !important;
}

.participationReportHeader {
  background-color: #77787b !important;
  color: #fff !important;
  display: flex !important;
  min-height: 64px !important;
  align-items: center;
  justify-content: flex-start;
  border-radius: 7px !important;
  padding: 15px 20px;
}

.participationOption:hover {
  cursor: pointer;
  color: #616161 !important;
}

.compareDataCycle {
  width: 100%;
  margin-right: 10px;
}

.compareDataCycle .MuiAutocomplete-inputRoot[class*="MuiInput-root"] {
  height: 50px;
}

.compareDataCycle .MuiFormControl-fullWidth {
  margin-top: -4px !important;
}

.compareIcon {
  align-self: end;
  padding: 0px 10px;
}

.chartSpinner {
  height: 200px;
  width: 200px;
  margin: auto;
  top: 50%;
  left: 50%;
  position: fixed;
}

.downloadButton {
  background-color: var(--blue);
  color: var(--app-text-color-white) !important;
  cursor: pointer;
  border-radius: 4px;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  height: 100%;
  aspect-ratio: 1/1;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.downloadButton:hover {
  background-color: var(--hover-grey) !important;
}

.hidden {
  display: none;
}

.charts-reports-header {
  font-size: 30px;
  color: var(--app-text-color-default) !important;
  margin-bottom: 25px;
  font-weight: bold;
  margin-top: 10px;
  width: 100%;
}

.loadingBox {
  width: 20%;
  aspect-ratio: 1 / 1;
  margin-left: 7vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

#demo-simple-select-outlined-label,
.MuiSelect-outlined.MuiSelect-outlined {
  padding: 0.8vh !important;
  color: var(--app-text-color-white);
}

#demo-simple-select-outlined-label {
  transform: translate(1px, -18px) scale(0.75) !important;
  transform-origin: top left !important;
}

.MuiSelect-iconOutlined {
  color: var(--app-text-color-white) !important;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
.MuiOutlinedInput-notchedOutline {
  border-color: var(--app-background-color-default) !important;
  border-width: 2px;
}