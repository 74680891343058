.chartSelectBox {
  color: var(--app-text-color-white) !important;
  background-color: var(--app-background-color-default) !important;
  border-radius: 7px;
  position: relative;
  width: 100%;
  margin: 10px 0px;
}

.chartSelectBoxContent {
  padding: 10px;
  position: relative;
  background-color: var(--app-background-color-white) !important;
  border-radius: 0px 0px 7px 7px;
  color: var(--app-text-color-default) !important;
  border: 1px solid var(--app-border-color-default) !important;
}

.chartSelectBoxContent p {
  padding: 1px !important;
}

.chartSelectBoxBanner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.chartSelectBoxBanner p {
  margin: 0 !important;
}

.chartSelectBoxBannerBorder {
  border-bottom: 1px solid var(--app-border-color-default) !important;
}

.chartSelectBoxIcons {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.displayedIcon {
  display: inline;
}

.hiddenIcon {
  display: none;
}

.visible {
  display: block;
}

.invisible {
  display: none;
}

.chartSelectBoxList {
  cursor: pointer;
}