.progressContainer {
  height: auto;
  margin: 5px;
  display: flex;
  flex-direction: column;
  color: var(--app-text-color-default) !important;;
}

.progressIconContainer {
  /* height: 75px;
  width: 75px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.progressIconContainer h2 {
  margin: 0;
}

.progressIcon {
  font-size: 3em;
}