.chooseSite {
  width: 40%;
  z-index: 20;
  display: flex;
  flex-direction: column;
  gap: 4vh;
  margin-left: 60%;
}

.siteAccordian {
  display: flex;
  width: 100%;
  gap: 12px;
  height: 100%;
}

.count {
  margin: 0 !important;
  background: var(--green);
  height: 100%;
  aspect-ratio: 1 / 1;
  color: #fff;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}