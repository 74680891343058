.statusBadgeInfoBox {
  color: var(--app-text-color-default) !important;
  background-color: var(--app-background-color-white) !important;
  border-radius: 7px;
  margin: 10px 0px;
}

.statusBadgeInfoBoxContent {
  padding: 10px;
  border-bottom: 1px solid var(--app-border-color-default) !important;
  border-left: 1px solid var(--app-border-color-default) !important;
  border-right: 1px solid var(--app-border-color-default) !important;
  border-bottom-right-radius: 7px;
  border-bottom-left-radius: 7px;
}

.statusBadgeInfoBoxBanner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  height: 6vh;
  border-top-right-radius: 7px;
  border-top-left-radius: 7px;
}

.statusBadgeInfoBoxBannerBorder {
  color: var(--app-background-color-white);
  background-color: var(--app-background-color-default);
  border: 1px solid var(--app-border-color-default) !important;
}