.customPannel {
  width: 100%;
  height: 85vh;
  padding: 3vh 3vw;
  display: flex;
  justify-content: space-between;
}

.summaryHolder {
  width: 70%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  cursor: pointer;
}

.infoHolder {
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: start;
  border-radius: 7px;
  overflow-y: scroll;
  cursor: pointer;
}

.customControls {
  width: 335px;
  border-radius: 5px !important;
}

@media screen and (max-width: 567px) {
  .customControls {
    width: 280px;
  }
}

#mapData {
  height: 92vh;
  width: 100%;
}

@media screen and (min-width: 1440px) {
  #mapData {
    height: 92vh;
  }
}

.mapFilter {
  background-color: #fff;
  color: #ccc !important;
  min-width: 15vw !important;
  max-width: 20vw;
  margin: 10px 0px;
  padding: 0px 10px;
  border-radius: 5px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}

.filterHolder {
  position: absolute;
  left: 5%;
  top: 5%;
  width: 330px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.controlHeaderMap p {
  padding: 0px 15px;
  line-height: 28px;
  display: flex;
  gap: 10px;
  align-items: center;
  width: 100%;
  padding: 0 0 0 15px !important;
}

.siteAccordian .controlHeaderMap p {
  justify-content: space-between;
}

.controlHeaderMap svg {
  color: #fff !important;
  font-size: 22px;
}

.controlContent {
  padding: 15px 20px;
}

.accordian {
  left: 60px;
  top: 10%;
  z-index: 99999;
}

.accordian2 .controlContent,
.siteAccordian .controlHeaderMap {
  background-color: #77787b;
  color: #fff;
  border-top: 1px solid #ccc;
}

.accordian1 .controlHeaderMap,
.accordian2 .controlHeaderMap {
  background-color: #77787b !important;
}

.accordian1 svg,
.accordian2 svg {
  font-size: 25px;
}

.accordian1 svg.hide,
.accordian2 svg.hide {
  font-size: 20px;
}

.css-yw020d-MuiAccordionSummary-expandIconWrapper {
  transform: none !important;
}

.controlHeaderMap.Mui-expanded .hide {
  display: block;
}

.controlHeaderMap.Mui-expanded .show {
  display: none;
}

.hide {
  display: none;
}

.topControls {
  display: flex;
  width: 100%;
  justify-content: space-around;
  margin-top: 40px;
  flex-wrap: wrap;
  gap: 10px;
  padding: 0 10px;
  height: max-content;
}

.topControls .Mui-expanded {
  height: 100%;
}

.accordianTop {
  margin: 0 !important;
  border-radius: 5px !important;
  height: 0;
}

.MuiPickersToolbar-toolbarLandscape {
  width: 70px !important;
}

h4.MuiPickersToolbarText-toolbarTxt {
  font-size: 20px;
  padding: 20px 0;
}

.MuiPickersBasePicker-pickerView {
  min-width: 260px;
}

.accordianTop .MuiFormControl-marginNormal {
  margin: 0 !important;
}

/* .accordianTop label + .MuiInput-formControl{
  margin: 0 !important;
} */

@media screen and (max-width: 831px) {
  .accordianTop {
    height: auto;
  }
}

.Select-dropdown {
  width: 100%;
}

.siteList {
  display: flex;
  flex-direction: column;
}

.siteList a {
  display: flex;
  gap: 10px;
  margin: 5px 0;
  cursor: pointer;
}

.siteList a svg {
  background-color: #77787b;
  color: #fff;
  font-size: 16px;
  padding: 4px;
  width: 25px;
  height: 25px;
  border-radius: 5px;
}

.siteList a:hover {
  color: #2b78fc;
}

.siteList a:hover svg {
  background-color: #2b78fc;
}

.progressChart {
  position: absolute;
  left: 415px;
  top: -70%;
  box-shadow: 1px 0px 6px 3px #00000066;
  background-color: #fff;
  border-radius: 5px;
}

.mapDownloadButton {
  background-color: var(--blue);
  color: var(--app-text-color-white) !important;
  cursor: pointer;
  border-radius: 4px;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  width: 100%;
  margin-top: 10px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.mapDownloadButton:hover {
  background-color: var(--hover-grey) !important;
}